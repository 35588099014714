<template>
  <div style="margin-left: 10%; margin-right: 10%; margin-top: 1rem">
    <div v-for="item in datalist" :key="item.index" style="margin-bottom: 2rem">
      <div
        style="
          color: #2285ff;
          text-align: left;
          font-size: 1.5rem;
          font-weight: 400;
          font-family: PingFang SC Medium, PingFang SC Medium-Medium;
          letter-spacing: 0.2rem;
          line-height: 4rem;
          display: flex;
          align-items: center;
        "
      >
        {{ item.title }}
      </div>
      <div style="text-align: left">
        <ul style="list-style-type: none; margin: 0; padding: 0">
          <li
            v-for="citem in item.list"
            :key="citem.index"
            style="
              color: #333333;
              text-align: left;
              font-size: 1.4rem;
              font-weight: 400;
              font-family: PingFang SC Medium, PingFang SC Medium-Medium;
              letter-spacing: 0.2rem;
              line-height: 4rem;
              display: flex;
              align-items: center;
            "
          >
            {{ citem }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contentlist',
  props: ['datalist'],
};
</script>

<style scoped>
</style>
