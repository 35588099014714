<template>
  <div style="display: flex;flex-direction: row;align-items: center;
                height: 12rem; margin-left: 10%">
    <div style="display: flex;flex-direction: row;
    align-items: center;height: 4rem;margin-right: 2rem;">
      <img :src="imgurl" style="height: 100%;"/>
    </div>
    <div style="display: flex;flex-direction: column;height: 4rem;justify-content: center">
      <div style="height: 50%;
        font-size: 1.6rem;font-weight:400;color: #2285ff;text-align: left;
font-family: PingFang SC Medium, PingFang SC Medium-Medium;
letter-spacing: 0.2rem;">
        {{title}}
      </div>
      <div style="height: 50%;
           justify-content: flex-end;display: flex;flex-direction: column;
           color: #969696;font-size: 1rem;font-weight: 400;text-align: left;
font-family: PingFang SC Medium, PingFang SC Medium-Medium;
letter-spacing: 0.05rem;
">
        {{en_title}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'paragraph.vue',
  props: ['imgurl', 'title', 'en_title'],
  data() {
    return {
      demo: '',
    };
  },
};
</script>

<style scoped>

</style>
