<template>
  <div class="banner">
    <div class="leftdiv">
      <div style="" class="left-div">
        <div class="text-top">{{ title }}</div>
        <div class="text">{{ body }}</div>
        <div class="flexcenterc dbutton" style="justify-content: left;flex-direction: row">
          <el-button class="flexcenterc" id="detail-button" @click="skip">了解详情</el-button>
          <el-button class="flexcenterc" id="call-us-button" @click="callUs">联系我们</el-button>
        </div>
      </div>
    </div>
    <div class="rightdiv">
       <div class="imgdiv">
        <img :src="image" class="image" :style="change"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: ['image', 'title', 'body', 'detailBtn', 'callUsBtn', 'change'],
  data() {
    return {};
  },
  methods: {
    callUs() {
      this.$router
        .push({
          name: 'Form',
          query: {
            id: this.$route.matched.slice(-1)[0].props.default.formId,
            groupName: this.$route.matched.slice(-1)[0].props.default.title,
          },
        })
        .catch((e) => e);
    },
    skip() {
      const bannerHeight = document.getElementsByClassName('banner')[0];
      let scrolly = bannerHeight.clientHeight;
      scrolly = bannerHeight.offsetHeight;
      window.scroll(0, scrolly);
    },
  },
};
</script>

<style scoped>
.banner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  height: 36vw;
  background-size: cover;
  background-color: #2285ff;
}

.leftdiv {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  text-align: left;
}

.left-div {
  position: relative;
  height: 50%;
  width: 70%;
  margin-left: 20%
}

.rightdiv {
  height: 100%;
  width: 49%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.imgdiv {
  width: 100%;
  height: 75%;
}

.image {
  width: 100%;
  height: 100%;
}
.dbutton{
  position: absolute;
  bottom: 0;
}

.text-top {
  position: absolute;
  color: #ffffff;
  font-size: 2vw;
}

.text {
  position: absolute;
  top: 4vw;
  color: #ffffff;
  font-size: 1vw;
  line-height: 1.8vw;
}

#detail-button {
  color: white;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 0;
  height: 2.5vw;
  width: 8vw;
  font-size: 0.8vw;
}

#detail-button:hover {
  background-color: white;
  color: #409eff;

}

.flexcenterc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#call-us-button {
  height: 2.5vw;
  color: #2086f9;
  border-radius: 0;
  border-color: white;
  font-size: 0.8vw;
  width: 8vw;
  margin-left: 1vw;
}
</style>
