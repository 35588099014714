<template>
  <div>
    <Solution-Header
      :body="body"
      :image="image"
      :show-buy-btn="showBuyBtn"
      :show-detail-btn="showDetailBtn"
      :title="title"
    />
    <!-- <ImageList :images="images"></ImageList> -->
    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/map.svg`)"
      :title="`产品服务背景`"
      :en_title="`Product Service Background`"
    ></Paragraph>
    <Contentlistb :datalist="datalist1"></Contentlistb>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/king.svg`)"
      :title="`产品优势`"
      :en_title="`Product Advantages`"
    ></Paragraph>
    <el-row style="margin-left: 15%; margin-right: 5%; white-space: nowrap">
      <el-col :span="13">
        <Titlestrlist :datalist="datalist2.slice(0, 2)"></Titlestrlist>
      </el-col>
      <el-col :span="11">
        <Titlestrlist :datalist="datalist2.slice(2, 4)"></Titlestrlist>
      </el-col>
    </el-row>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`产品特色`"
      :en_title="`Feature of Product`"
    ></Paragraph>
    <!-- <div style="width: 80%; margin-left: 10%"> -->
    <Logotitlebox :datalist="datalistx3"></Logotitlebox>

    <Paragraph
      :imgurl="require('@/assets/image/contentsvg/db.svg')"
      :title="`服务特色`"
      :en_title="`Service Features`"
    ></Paragraph>
    <Titlestrlist :datalist="datalist"></Titlestrlist>

    <Paragraph
      :imgurl="require(`@/assets/image/contentsvg/db.svg`)"
      :title="`经济与社会效益分析`"
      :en_title="`Economic & Social Benefit Analysis`"
    ></Paragraph>
    <Logotitlebox :datalist="datalistx4"></Logotitlebox>
  </div>
</template>

<script>
// import ImageList from '@/components/common/ImageList';
import SolutionHeader from '@/components/common/SolutionHeader';
import Paragraph from '@/components/chain/basic/common/paragraph';
import Titlestrlist from '@/components/chain/basic/common/titlestrlist';
// import Lettertitleblock from '@/components/chain/basic/common/lettertitleblock';
import Contentlistb from '@/components/chain/basic/common/contentlistb';
import Logotitlebox from '@/components/chain/basic/common/logotitlebox';

export default {
  name: 'BaaSLeaflet',
  components: {
    // ImageList,
    SolutionHeader,
    Paragraph,
    Titlestrlist,
    // Lettertitleblock,
    Contentlistb,
    Logotitlebox,
  },
  data() {
    return {
      title: '数字资产服务',
      body:
      '区块链应用于数字资产领域，相比于传统中心化系统，优势在于：资产一旦在区块链上发行，后续流通环节可以不依赖于发行方系统，'
      + '资产流通由单中心控制变成社会化传播，任何有资源的渠道都可以成为资产流通的催化剂。因此，区块链能极大地提升数字资产流通效率，真正达到“多方发行、自由流通”的效果。',
      // TODO: REPLACE WITH PRIVACY PANEL IMAGE
      image: require('@/assets/image/chain/va/asset/asset.svg'),
      showDetailBtn: true,
      showBuyBtn: true,
      images: [
        require('@/assets/image/chain/va/asset/10_01.jpg'),
        require('@/assets/image/chain/va/asset/10_02.jpg'),
        require('@/assets/image/chain/va/asset/10_03.jpg'),
        require('@/assets/image/chain/va/asset/10_04.jpg'),
        require('@/assets/image/chain/va/asset/10_05.jpg'),
      ],
      datalist1: [
        {
          title: '',
          list: [
            '资产进入流通后，仍必须依赖资产发行方系统才能完成使用、转移，这就将资产流通范围限制在发行方系统用户群；',
            '传统的资产流通渠道有限，几乎都依赖于大渠道，行业大渠道由于垄断地位大幅增加费用，从而导致流程成本显著提高，小渠道及个人难以在流通环节发挥作用。',
          ],
        },
      ],
      datalist2: [
        {
          title: '',
          content: '建立公开化、透明化、可追溯的线上预付卡管理体系',
        },
        {
          title: '',
          content: '简化线上预付卡发行的手续和操作',
        },
        {
          title: '',
          content: '降低线上预付卡的发行成本',
        },
        {
          title: '',
          content: '打通线上预付卡的流通壁垒',
        },
      ],
      datalist: [
        {
          title: '身份信息认证',
          content:
            '多种身份认证手段在线鉴别核实个人、企业身份确保电子签署者真实的签署身份，不可仿冒与否认。',
        },
        {
          title: 'CA中心认证',
          content:
            '国内第一家权威专业的第三方CA认证机构提供电子认证服务提供合法可靠的电子认证、电子签名/签章、时间戳等服务。',
        },
        {
          title: '电子签章',
          content:
            '为用户提供专业、可信、便捷、高性的电子签章服务解决企业运营中签署可靠电子文件需求保障电子文件真实、完整、不可抵赖性。',
        },
        {
          title: '电子签署',
          content:
            '严格遵循《电子签名法》，符合国家密码管理要求、兼容国际标准，支持USBkey/手写/移动/短信/指纹/人脸/扫码/批量签署满足多场景、各行业的电子签署需求',
        },
        {
          title: '数据存证',
          content:
            '采用文件加密分散存储确保信息和数据安全存证报告详细记载业务全流程信息，形成可追溯的完整证据链对接第三方公证机关、司法鉴定机构，确保签署结果可信',
        },
        {
          title: '司法举证',
          content:
            '随时随地多种手段线上线下验证签名有效性真实性可信完整的电子证据链条，为电子签署提供可靠性保障CA验证报告、公证书、司法鉴定报告，已获法院判例认可。',
        },
      ],
      datalistx3: [
        {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '资产发行端',
          list: [
            '拥有1-2个节点',
            '通过多方参与和监管的分布式账本的授信',
            '发行的资产更加可信，发行的成本更低',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/fly.svg'),
          title: '资产技术服务方',
          list: [
            '拥有1个节点',
            '保证为整套系统提供公开、透明',
            '可监管的账本',
            '同时只需维护一套系统',
            '大幅降低技术成本',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/setting.svg'),
          title: '流通服务方',
          list: [
            '拥有1-2个节点',
            '在公开、透明、可追溯的分布式账本上',
            '保证卡券的流通性和交易效率',
            '&nbsp;',
            '&nbsp;',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '监管机构',
          list: [
            '拥有1-2个节点',
            '提供政府监管和信用背书',
            '保证账本和资产的真实性和安全性',
            '&nbsp;',
            '&nbsp;',
          ],
        },
      ],
      datalistx4: [
        {
          imageurl: require('@/assets/image/contentsvg/star.svg'),
          title: '',
          list: [
            '对于政府监管部门',
            '可以根据权限设置',
            '对发卡商户进行访问和审查',
            '同时予以签名认证',
            '审查结果供全链条上的参与者共享',
            '提高了监管效率和效果',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/book.svg'),
          title: '',
          list: [
            '对于发卡商户',
            '可以通过智能合约将信息传递给',
            '链上相关方',
            '有助于企业建立起可信生态',
            '发卡商户还能以可信的数据为背书',
            '获得融资、贷款等金融支持',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/tikit2.svg'),
          title: '',
          list: [
            '对于预付卡渠道商',
            '可以放心大胆地投入',
            '在这套公开、透明、可追溯的系统中促进预付卡的交易和流通',
            '从而获得规模效应',
            '达到降本增效增收入的良好效果',
          ],
        },
        {
          imageurl: require('@/assets/image/contentsvg/people.svg'),
          title: '',
          list: [
            '对于持卡消费者',
            '可以通过查询区块链溯源信息',
            '获得可信的线上预付卡发行和流通等信息',
            '让消费更放心',
            '&nbsp;',
            '&nbsp;',
          ],
        },
      ],
    };
  },
};
</script>

<style scoped></style>
